import { setDateWithTimezone, toISOStringWithoutTimezone } from '@systemeio/ui-shared'
import { removeTimezoneFromString } from 'shared/utils/date-time-format'

export const getDateRangeSinceDate = (date?: string, timeZone?: string): [string, string] => {
  const today = setDateWithTimezone('', timeZone)
  today.setHours(23, 59, 59, 999)
  return [removeTimezoneFromString(date || ''), toISOStringWithoutTimezone(today)]
}

export const getToday = (timeZone?: string): Date => {
  const today = setDateWithTimezone('', timeZone)
  today.setHours(23, 59, 59, 999)
  return today
}

export const getMonthDateRange = (timeZone?: string): [string, string] => {
  const today: Date = setDateWithTimezone('', timeZone)
  today.setHours(23, 59, 59, 999)
  const lastDate: Date = new Date(today)
  lastDate.setDate(lastDate.getDate() - 30)
  lastDate.setHours(0, 0, 0, 0)

  return [toISOStringWithoutTimezone(lastDate), toISOStringWithoutTimezone(today)]
}

export const getWeekDateRange = (timeZone?: string): [string, string] => {
  const today: Date = setDateWithTimezone('', timeZone)
  today.setHours(23, 59, 59, 999)
  const lastDate: Date = new Date(today)
  lastDate.setDate(lastDate.getDate() - 7)
  lastDate.setHours(0, 0, 0, 0)

  return [toISOStringWithoutTimezone(lastDate), toISOStringWithoutTimezone(today)]
}

export const getThisMonthDateRange = (timeZone?: string): [string, string] => {
  const today: Date = setDateWithTimezone('', timeZone)
  today.setHours(23, 59, 59, 999)
  const firstDay: Date = new Date(today)
  firstDay.setDate(1)
  firstDay.setHours(0, 0, 0, 0)

  return [toISOStringWithoutTimezone(firstDay), toISOStringWithoutTimezone(today)]
}

export const getDateRangeWithDaysDifference = (
  daysDifference: number,
  timeZone?: string,
): [string, string] => {
  const today: Date = setDateWithTimezone('', timeZone)
  today.setHours(23, 59, 59, 999)
  const lastDate: Date = new Date(today)
  lastDate.setDate(lastDate.getDate() - daysDifference)
  lastDate.setHours(0, 0, 0, 0)

  return [toISOStringWithoutTimezone(lastDate), toISOStringWithoutTimezone(today)]
}
