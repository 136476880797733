import { CurrencyEnum, LocaleEnum } from '@systemeio/ui-shared'
import { getLanguageCodeByLocale } from 'shared/enums/language-code-enum'

export const currencyFormat = (
  amount: number,
  currency: CurrencyEnum,
  locale?: LocaleEnum,
  noDecimals?: boolean,
) =>
  Intl.NumberFormat(getLanguageCodeByLocale(locale ?? LocaleEnum.ENGLISH), {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: noDecimals ? 0 : undefined,
    minimumFractionDigits: noDecimals ? 0 : 2, // we need 2 fractional digits for tests
  })
    .format(amount)
    .replace(/\s+/g, ' ')
    .trim()
