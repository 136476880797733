import { DateRangePicker } from '@systemeio/ui-shared'
import { ChartBoxSection } from 'modules/main/components/chart-box-section'
import { EventBoxSection } from 'modules/main/components/event-box-section/event-box-section'
import { useMain } from 'modules/main/hooks/use-main'
import { MainFilterInterface } from 'modules/main/types/main-filter-interface'
import React, { useEffect, useRef } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import useWindowSize from 'shared/hooks/use-window-size'
import { useFilterWithDate } from 'shared/route-query/hooks/use-filter/use-filter-with-date'
import { getMonthDateRange, getToday } from 'shared/utils/get-dates'

const defaultFilter: MainFilterInterface = {
  dateSince: null,
  dateTill: null,
}

export const Main = () => {
  const { t } = useLocoTranslation()
  const { user, intlLocale } = useUser()

  const { filter, setFilter } = useFilterWithDate(defaultFilter, getMonthDateRange)
  const { revenue } = useMain(filter)

  const chartBoxRef = useRef<HTMLDivElement>(null)
  const eventBoxRef = useRef<HTMLDivElement>(null)
  const { width } = useWindowSize()

  const chartsHeight = chartBoxRef.current?.getBoundingClientRect().height

  useEffect(() => {
    if (chartBoxRef.current && eventBoxRef.current && revenue) {
      if (Object.keys(revenue).length > 3 && width >= 1024) {
        eventBoxRef.current.style.height = `${chartsHeight}px`
      } else if (width < 1024) {
        eventBoxRef.current.style.height = ''
      }
    }
  }, [chartsHeight, revenue, width])

  return (
    <div className={'flex flex-col gap-5 mb-[53px]'}>
      <div className={`flex flex-col sm:flex-row justify-between gap-3 items-center`}>
        <h1 className="flex items-center whitespace-nowrap h-[42px]">
          {t('dashboard.main.title')}
        </h1>
        <div className={'w-full sm:w-fit lg:w-[326px]'}>
          <DateRangePicker
            light
            maxDate={getToday(user?.timezone)}
            getDefaultDate={() => getMonthDateRange(user?.timezone)}
            date={[filter.dateSince, filter.dateTill]}
            onChange={date => {
              const [dateSince, dateTill] = date
              setFilter({ dateSince, dateTill })
            }}
            timezone={user?.timezone}
            createdAt={user?.createdAt}
            intlLocale={intlLocale}
          />
        </div>
      </div>
      <div className={'flex justify-between flex-col lg:flex-row gap-6 relative'}>
        <ChartBoxSection
          ref={chartBoxRef}
          className={
            revenue && Object.keys(revenue).length > 3 ? 'w-full lg:w-[calc(100%-350px)]' : 'w-full'
          }
          filter={filter}
        />
        <EventBoxSection ref={eventBoxRef} filter={filter} />
      </div>
    </div>
  )
}

export default Main
