import {
  ContactFieldValueInterface,
  ContactSlugEnum,
  CountryEnum,
  Tooltip,
  getContactFieldTitle,
} from '@systemeio/ui-shared'
import { ContactId } from 'modules/contacts/types/contact-interface'
import { CustomerTypeEnum } from 'modules/profile/user-settings/enums/customer-type-enum'
import React from 'react'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { usePhoneInput } from 'shared/react-international-phone'
import { getSingleCountry } from 'shared/utils/get-country-data'
import { CONTACT_FIELDS_SORT_ORDER } from '../hooks/use-contact-fields'

interface ContactInfoTooltipProps<T extends ContactFieldValueInterface> {
  children?: JSX.Element | string
  fields: T[]
  referredByEmail?: string
  type?: keyof typeof CustomerTypeEnum
  contactId?: ContactId
  oldDesignedLink?: boolean
  wrapperClassName?: string
}

const ContactInfoTooltip = <T extends ContactFieldValueInterface>({
  children,
  fields,
  referredByEmail,
  type,
  contactId,
  oldDesignedLink,
  wrapperClassName,
}: ContactInfoTooltipProps<T>) => {
  const { t } = useLocoTranslation()
  const { user } = useUser()
  const phoneNumber = fields.find(el => el.contactField.slug === ContactSlugEnum.PhoneNumber)?.value
  const { phone: formattedPhoneNumber } = usePhoneInput({ value: phoneNumber })
  const getSlugValue = (slug: ContactSlugEnum, value: string) => {
    switch (slug) {
      case ContactSlugEnum.Country:
        return Object.values(CountryEnum).includes(value as CountryEnum)
          ? getSingleCountry(value as CountryEnum, user?.dashboardLocale)
          : value
      case ContactSlugEnum.PhoneNumber: {
        return formattedPhoneNumber
      }
      default: {
        return value
      }
    }
  }

  const sortedFields = [...fields].sort((a, b) => {
    const indexA = CONTACT_FIELDS_SORT_ORDER.indexOf(a.contactField.slug as ContactSlugEnum)
    const indexB = CONTACT_FIELDS_SORT_ORDER.indexOf(b.contactField.slug as ContactSlugEnum)

    if (indexA === -1) return 1
    if (indexB === -1) return -1

    return indexA - indexB
  })

  return (
    <Tooltip
      label={
        <div className="flex flex-col items-start gap-2 text-start">
          {sortedFields.map(el => {
            if (Object.values(ContactSlugEnum).includes(el.contactField.slug as ContactSlugEnum)) {
              return (
                <p key={el.contactField.slug}>
                  <span className="font-bold text-darkblue">
                    {getContactFieldTitle(el.contactField)}:{' '}
                  </span>
                  <span>{getSlugValue(el.contactField.slug as ContactSlugEnum, el.value)}</span>
                </p>
              )
            } else {
              return (
                <p key={el.contactField.fieldName}>
                  <span className="font-bold text-darkblue">{el.contactField.fieldName}: </span>
                  <span>{el.value}</span>
                </p>
              )
            }
          })}
          {type && (
            <p>
              <span className="font-bold text-darkblue">{t('global.type')}: </span>
              <span>{type}</span>
            </p>
          )}
          <p>
            <span className="font-bold text-darkblue">{t('dashboard.user.affiliate')}: </span>
            <span>{referredByEmail ? referredByEmail : t('global.none')}</span>
          </p>
        </div>
      }
      mode={'hover'}
      className="truncate w-full max-w-fit"
      wrapperClassName={`${wrapperClassName || 'w-full'}`}
    >
      {!contactId ? (
        children
      ) : (
        <LinkWithoutPrefetch
          className={
            oldDesignedLink
              ? 'underline hover:text-gray-300/70 truncate'
              : 'primary-link w-full truncate'
          }
          href={`/contacts/${contactId}`}
        >
          {children}
        </LinkWithoutPrefetch>
      )}
    </Tooltip>
  )
}

export default ContactInfoTooltip
