import MainComponent from 'modules/main/components/main'
import { MainLoco } from 'modules/main/constants/main-loco'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Loader } from 'shared/components/loader'
import useUser from 'shared/hooks/use-user'
import { getBaseServerSideProps } from 'shared/utils/get-base-server-side-props'

const Main: NextPage = () => {
  const router = useRouter()
  const { isLoading, isWsAssistant } = useUser()
  useEffect(() => {
    if (isWsAssistant) router.replace('/funnels')
  }, [router, isWsAssistant])

  return isLoading || isWsAssistant ? (
    <div className={'flex justify-center'}>
      <Loader />
    </div>
  ) : (
    <MainComponent />
  )
}

export const getServerSideProps = getBaseServerSideProps(MainLoco)

export default Main
