import { ContactSlugEnum } from '@systemeio/ui-shared'
import { CONTACT_API } from 'modules/contacts/api/contact-api'
import { ContactFieldApiInterface } from 'modules/contacts/types/contact-filter-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export const CONTACT_FIELDS_SORT_ORDER: ContactSlugEnum[] = [
  ContactSlugEnum.Email,
  ContactSlugEnum.FirstName,
  ContactSlugEnum.Surname,
  ContactSlugEnum.StreetAddress,
  ContactSlugEnum.StreetNumber,
  ContactSlugEnum.Neighborhood,
  ContactSlugEnum.Postcode,
  ContactSlugEnum.City,
  ContactSlugEnum.State,
  ContactSlugEnum.Country,
  ContactSlugEnum.PhoneNumber,
  ContactSlugEnum.CompanyName,
  ContactSlugEnum.TaxNumber,
]

export function useContactFields(shouldFetch: boolean = true) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    ContactFieldApiInterface
  >({
    method: RequestMethodsEnum.get,
    afterFetch: async data => {
      return {
        ...data,
        items: data.items.sort((a, b) => {
          const indexA = CONTACT_FIELDS_SORT_ORDER.indexOf(a.slug as ContactSlugEnum)
          const indexB = CONTACT_FIELDS_SORT_ORDER.indexOf(b.slug as ContactSlugEnum)

          if (indexA === -1) return 1
          if (indexB === -1) return -1

          return indexA - indexB
        }),
      }
    },
  })

  const { data: fields, mutate: mutateFields } = useSWR(() => {
    if (user && shouldFetch) {
      return `${CONTACT_API}/fields/list?pagination[limit]=50`
    }
    return null
  }, fetcher)

  return { fields, mutateFields }
}
